import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit'
import MenuItem from './menuItem';
import BoKho from "../img/Bo Kho.jpg";
import BeefBall from "../img/Beef Balls Pho.jpg";
import BunBoHue from "../img/Bun Rieu.jpg";
import ChickenPho from "../img/Chicken Pho.jpg";
import ChickenWings from "../img/Chicken Wings.jpg";
import Curry from "../img/Curry.jpg";
import EggRolls from "../img/Eggrolls.jpg";
import FilletMignon from "../img/Fillet Mignon Pho.jpg";
import MeatRiceVermicelli from "../img/Meat Rice Vermicelli.jpg";
import PotSticker from "../img/Potsticker.jpg";
import ShakenBeef from "../img/shaken beef.jpg";
import ShrimpEggrollMeatRiceVermicelli from "../img/Shrimp Eggroll Meat Rice Vermicelli.jpg";
import SpecialPho from "../img/Special Pho.jpg";
import VegetablePho from "../img/Vegetable Pho.jpg";
import WontonSoup from "../img/Wonton Soup.jpg";

export default class MenuNormal extends Component {
  constructor(props){
    super(props);
    this.state = {
      small_width: false,
      pointerEvent: (window.innerWidth < 1000) ? false : true
    };
  }

  render() {
    return (
      <MDBContainer className='text-md-start mt-3 mb-4'>
        <MDBRow className='mt-3 wd-100'>
          <MDBCol className='border-end border-dark wd-100'>
            <div className='menu_item_categorize'>
              <div className='menu_item_category_title_wrapper'>
                <h2 className='menu_item_category_title'>APPETIZERS: KHAI VỊ</h2>
                <MenuItem name="[A1]: Summer Rolls: Nem Nướng" price="$5.99/2 rolls" pointerEvent={this.state.pointerEvent} ingredient="Grilled Pork Wrapped with Lettuce, Cilantro, Cucumber, Chives, Mint, Served with House Special Sauce" picture=""></MenuItem>
                <MenuItem name="[A2]: Spring Rolls: " price="$5.99/2 rolls" pointerEvent={this.state.pointerEvent} ingredient="Fresh Shrimp, Wrapped with Lettuce, Cilantro, Chives, Mint, Served with Peanut Hoisin Sauce" picture=""></MenuItem>
                <MenuItem name="[A4]: Grilled Beef Salad Roll: " price="$5.99/2 rolls" pointerEvent={this.state.pointerEvent} ingredient="Wrapped with Vermicelli, Lettuce, Chives, and Mint, Served with Peanut Hoisin Sauce" picture=""></MenuItem>
                <MenuItem name="[A5]: Vegetarian Roll: " price="$7.99/3 rolls" pointerEvent={this.state.pointerEvent} ingredient="Wrapped with Shredded Tofu, Jicama, Carrot, and Basil. Served with Peanut Hoisin Sauce" picture=""></MenuItem>
                <MenuItem name="[A6]: Egg Roll: " price="$7.99/3 rolls" pointerEvent={this.state.pointerEvent} ingredient="Authentic Vietnamese Egg Roll, Crispy Rice Paper Roll with Pork, Clear Vermicelli, Taro, Carrot and Onion. Served with Lime Chilli Fish Sauce" picture={EggRolls}></MenuItem>
                <MenuItem name="[A6 VG]: Vegetarian Egg Roll: " price="$7.99/3 rolls" pointerEvent={this.state.pointerEvent} ingredient="Authentic Vietnamese Egg Roll. Cripsy Rice Paper Roll, Clear Vermicelli, Taro, Carrot and Onion. Served with Lime Chilli Fish Sauce" picture={EggRolls}></MenuItem>
                <MenuItem name="[A7]: Sizzling Crepes " price="$13.99" pointerEvent={this.state.pointerEvent} ingredient="Pan Fried Rice Flour Crepes Filled with Shrimp, Pork, Onion, Green Bean and Bean Sprout. Served with Asian Green and Lime Chilli Fish Sauce" picture=""></MenuItem>
                <MenuItem name="[A8] Pot Sticker: " price="$7.99/8 pieces" pointerEvent={this.state.pointerEvent} ingredient="" picture={PotSticker} rotate={-90} width={80} height={80}></MenuItem>
                <MenuItem name="[A9] Chicken Wings: " price="$7.99/5 pieces" pointerEvent={this.state.pointerEvent} ingredient="Handmade Deep Fried Chicken Wings" picture={ChickenWings}></MenuItem>
                <MenuItem name="[A10] French Fries: " price="$3.99" pointerEvent={this.state.pointerEvent} ingredient="" picture=""></MenuItem>
                <MenuItem name="[A11] Sweet Potato Fries: " price="$5.25" pointerEvent={this.state.pointerEvent} ingredient="" picture=""></MenuItem>
                <MenuItem name="[A12] Chicken Popcorn: " price="$6.25" pointerEvent={this.state.pointerEvent} ingredient="" picture=""></MenuItem>
                <MenuItem name="[A13] Pepper Salt Calamari: " price="$10.99" pointerEvent={this.state.pointerEvent} ingredient="" picture=""></MenuItem>
              </div>
            </div>
          </MDBCol>
          <MDBCol className='wd-100'>
            <div className='menu_item_categorize'>
              <h2 className="menu_item_category_title">COLD & HOT BEVERAGES: GIẢI KHÁT NÓNG & LẠNH</h2>
              <MenuItem name="[D1] French Coffee Hot or Ice: " price="$4.50" pointerEvent={this.state.pointerEvent} ingredient="" picture=""></MenuItem>
              <MenuItem name="[D2] French Coffe with Condensed Milk Hot or Ice: " price="$4.50" pointerEvent={this.state.pointerEvent} ingredient="" picture=""></MenuItem>
              <MenuItem name="[D3] Thai Ice Tea: " price="$5.75" pointerEvent={this.state.pointerEvent} ingredient="" picture=""></MenuItem>
              <MenuItem name="[D4] Lemon Ice Tea: " price="$5.75" pointerEvent={this.state.pointerEvent} ingredient="" picture=""></MenuItem>
              <MenuItem name="[D5] Soda Lemonade Tea: " price="$5.75" pointerEvent={this.state.pointerEvent} ingredient="" picture=""></MenuItem>
              <MenuItem name="[D6] Jasmine Ice Tea: " price="$4.25" pointerEvent={this.state.pointerEvent} ingredient="" picture=""></MenuItem>
              <MenuItem name="[D7] Jasmine Hot Tea Pot: " price="$5.99" pointerEvent={this.state.pointerEvent} ingredient="" picture=""></MenuItem>
              <MenuItem name="[D8] Soft Drink: " price="$3.75" pointerEvent={this.state.pointerEvent} ingredient="Coke, Diet Coke, Sprite, Raspberry Ice Tea, Dr. Pepper, Orange Fountain, Root Beer" picture=""></MenuItem>
            </div>
            </MDBCol>
        </MDBRow>
        <MDBRow className='mt-3 wd-100'>
          <MDBCol className='border-end border-dark wd-100'>
            <div className='menu_item_categorize'>
              <h2 className="menu_item_category_title">RICE VERMICELLI: BÚN</h2>
              <MenuItem name="[B1] Vermicelli With Grilled Meat: " price="$13.25" pointerEvent={this.state.pointerEvent} ingredient="Your Choice: Grilled Pork or Beef" picture={MeatRiceVermicelli} width={85} height={85} rotate={90}></MenuItem>
              <MenuItem name="[B2] Vermicelli With Vietnamese Egg Rolls: " price="$13.25" pointerEvent={this.state.pointerEvent} ingredient="" picture=""></MenuItem>
              <MenuItem name="[B3] Vermicelli With Egg Roll And Grilled Shrimp" price="$13.50" pointerEvent={this.state.pointerEvent} ingredient="" picture=""></MenuItem>
              <MenuItem name="[B4] Vermicelli With Egg Roll And Grilled Meat: " price="$13.50" pointerEvent={this.state.pointerEvent} ingredient="Your Choice: Grilled Pork or Beef" picture=""></MenuItem>
              <MenuItem name="[B26] Vermicelli With Egg Rolls, Grilled Shrimp and Grilled Meat: " price="$16.30" pointerEvent={this.state.pointerEvent} ingredient="Your Choice: Grilled Pork or Beef" picture={ShrimpEggrollMeatRiceVermicelli}></MenuItem>
            </div>
            <div className='menu_item_categorize'>
              <h2 className="menu_item_category_title">RICE VERMICELLI SOUP: BÚN NƯỚC</h2>
              <MenuItem name="[B7] Central Vietnamese Spicy Beef Shank, Pork Hock With Vermicelli Soup: " price="$13.80" pointerEvent={this.state.pointerEvent} ingredient="" picture={BunBoHue}></MenuItem>
              <MenuItem name="[B8] Crab Paste, Tofu, Tomato With Vermicelli Soup: " price="$13.80" pointerEvent={this.state.pointerEvent} ingredient="" picture=""></MenuItem>
              <MenuItem name="[B9] Potato Carrot Chicken Curry With Vermicelli Soup: " price="$13.80" pointerEvent={this.state.pointerEvent} ingredient="" picture={Curry}></MenuItem>
            </div>
            <div className='menu_item_categorize'>
              <h2 className="menu_item_category_title">BORKEN RICE DISHES: CƠM TẤM DĨA</h2>
              <MenuItem name="[C17] Borken Rice With Egg Loaf: " price="$14.25" pointerEvent={this.state.pointerEvent} ingredient="Your Choice: Grilled Pork or Beef or Chicken." picture=""></MenuItem>
              <MenuItem name="[C19] Broken Rice With Egg Loaf And Grilled Pork Chop: " price="$14.75" pointerEvent={this.state.pointerEvent} ingredient="" picture=""></MenuItem>
              <MenuItem name="[C20] Broken Rice With Egg Loaf, Grilled Shrimp: " price="$16.30" pointerEvent={this.state.pointerEvent} ingredient="Your Choice Grilled Pork or Beef or Chicken" picture=""></MenuItem>
            </div>
          </MDBCol>
          <MDBCol className='wd-100'>
            <div className='menu_item_categorize'>
              <h2 className="menu_item_category_title">NOODLE SOUP: PHỞ</h2>
              <MenuItem name="[B10] Rice Noodle Soup With Sliced Rare Steak and Beef Ball: " price="$13.50" pointerEvent={this.state.pointerEvent} ingredient="" picture={BeefBall} width={85} height={85} rotate={-90}></MenuItem>
              <MenuItem name="[B11] Rice Noodle Soup With Fillet Mignon: " price="$14.75" pointerEvent={this.state.pointerEvent} ingredient="" picture={FilletMignon} width={85} height={85} rotate={90}></MenuItem>
              <MenuItem name="[B12] Tofu Vegetable Noodle Soup: " price="$13.50" pointerEvent={this.state.pointerEvent} ingredient="" picture={VegetablePho} width={90} height={90} rotate={-90}></MenuItem>
              <MenuItem name="[B13] Chicken Noodle Soup: " price="$13.50" pointerEvent={this.state.pointerEvent} ingredient="" picture={ChickenPho} width={90} height={90} rotate={-90}></MenuItem>
              <MenuItem name="[B14] Thin Egg Noodle Wonton Soup: " price="$14.75" pointerEvent={this.state.pointerEvent} ingredient="Filled With Pork and Shrimp" picture=""></MenuItem>
              <MenuItem name="[B15] Wonton Soup: " price="$13.50" pointerEvent={this.state.pointerEvent} ingredient="" picture={WontonSoup} width={80} height={80} rotate={90}></MenuItem>
              <MenuItem name="[B16] Udon Noodle With Shrimp And PorkHock Or Chicken: " price="$14.75" pointerEvent={this.state.pointerEvent} ingredient="" picture=""></MenuItem>
              <MenuItem name="[B27] Special Rice Noodle Soup With Steak Flank, Tendon, Tripe & Brisket: " price="$15.75" pointerEvent={this.state.pointerEvent} ingredient="" picture={SpecialPho} width={90} height={90} rotate={-90}></MenuItem>
            </div>
            <div className='menu_item_categorize'>
              <h2 className="menu_item_category_title">JASMINE RICE DISHES: CƠM DĨA</h2>
              <MenuItem name="[C22] Shaken Beef: " price="$16.30" ingredient="Cubed Steak Wok, Seared With Garlic, Onion and Black Pepper. Served With Lettuce, Sliced Tomato." pointerEvent={this.state.pointerEvent} picture={ShakenBeef}></MenuItem>
              <MenuItem name="[C23] Roti Chicken: " price="$16.30" pointerEvent={this.state.pointerEvent} ingredient="Half Golden Roasted Comish Hen. Served With Lettuce, Sliced Tomato" picture=""></MenuItem>
              <MenuItem name="[C24] Chicken Curry With Potatoes And Carrot Served With Jasmine Rice: " price="$13.80" pointerEvent={this.state.pointerEvent} ingredient="" picture=""></MenuItem>
            </div>
            <div className='menu_item_categorize'>
              <h2 className="menu_item_category_title">CHEF SPECIAL</h2>
              <MenuItem name="[C25] Beef Stew Served With Rice Noodle or Egg Noodle: " price="$14.75" pointerEvent={this.state.pointerEvent} ingredient="" picture={BoKho}></MenuItem>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }
}
